import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FORM_FIELDS } from '../../../constants';
import { captureException } from '@sentry/nextjs';
import { extendFormData } from '../../../store/reducers/formStore';

const addressDadata = {
    value: 'г Москва, Мещерский пр-кт, д 1/15',
    data: {
        postal_code: '119618',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: null,
        area_kladr_id: null,
        area_with_type: null,
        area_type: null,
        area_type_full: null,
        area: null,
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Западный',
        city_district_fias_id: null,
        city_district_kladr_id: null,
        city_district_with_type: null,
        city_district_type: null,
        city_district_type_full: null,
        city_district: null,
        settlement_fias_id: null,
        settlement_kladr_id: null,
        settlement_with_type: null,
        settlement_type: null,
        settlement_type_full: null,
        settlement: null,
        street_fias_id: '2c571018-806f-4b93-a3f8-182e6eac49ef',
        street_kladr_id: '77000000000184800',
        street_with_type: 'Мещерский пр-кт',
        street_type: 'пр-кт',
        street_type_full: 'проспект',
        street: 'Мещерский',
        stead_fias_id: null,
        stead_cadnum: null,
        stead_type: null,
        stead_type_full: null,
        stead: null,
        house_fias_id: 'cb16a948-7663-49ba-bf42-e0e0a46ba499',
        house_kladr_id: '7700000000018480001',
        house_cadnum: null,
        house_type: 'д',
        house_type_full: 'дом',
        house: '1/15',
        block_type: null,
        block_type_full: null,
        block: null,
        entrance: null,
        floor: null,
        flat_fias_id: null,
        flat_cadnum: null,
        flat_type: null,
        flat_type_full: 'кв',
        flat: '42',
        flat_area: null,
        square_meter_price: '244692',
        flat_price: null,
        room_fias_id: null,
        room_cadnum: null,
        room_type: null,
        room_type_full: null,
        room: null,
        postal_box: null,
        fias_id: 'cb16a948-7663-49ba-bf42-e0e0a46ba499',
        fias_code: null,
        fias_level: '8',
        fias_actuality_state: '0',
        kladr_id: '7700000000018480001',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45268588000',
        oktmo: '45326000',
        tax_office: '7729',
        tax_office_legal: '7729',
        timezone: 'UTC+3',
        geo_lat: '55.666244',
        geo_lon: '37.42094',
        beltway_hit: 'OUT_MKAD',
        beltway_distance: '1',
        metro: null,
        divisions: null,
        qc_geo: '0',
        qc_complete: '5',
        qc_house: '2',
        history_values: null,
        unparsed_parts: null,
        source: null,
        qc: '0',
    },
};

const data = {
    [FORM_FIELDS.AMOUNT]: '220000',
    [FORM_FIELDS.PERIOD]: 'P3Y',
    [FORM_FIELDS.REGION]: 'Москва',
    [FORM_FIELDS.REGION_LOCATION_ROUTE]: '6.83.',
    [FORM_FIELDS.PURPOSE]: 'Money',
    [FORM_FIELDS.LAST_NAME]: 'Иванов',
    [FORM_FIELDS.FIRST_NAME]: 'Матвей',
    [FORM_FIELDS.MIDDLE_NAME]: 'Петрович',
    [FORM_FIELDS.FULL_NAME]: 'Иванов Матвей Петрович',
    [FORM_FIELDS.EMAIL]: 'testprocessor.00001.200.900@test.ru',
    [FORM_FIELDS.PASSWORD]: '123456',

    [FORM_FIELDS.BIRTHDAY]: '10.10.1988',
    [FORM_FIELDS.BIRTH_PLACE]: 'Москва',
    [FORM_FIELDS.PASSPORT_NUMBER]: '11 11 111111',
    [FORM_FIELDS.PASSPORT_DATE]: '10.10.2012',
    [FORM_FIELDS.UNIT_CODE]: '111-111',
    [FORM_FIELDS.ISSUED]: 'РУВД г. Москвы',
    [FORM_FIELDS.REGISTRATION_STREET]: 'г Москва, Мещерский пр-кт, д 1/15',
    [FORM_FIELDS.REGISTRATION_ROUTE]: '6.83.',
    [FORM_FIELDS.REGISTRATION_APARTMENT]: '42',
    [FORM_FIELDS.REGISTRATION_DATE]: '10.10.2012',
    [FORM_FIELDS.RESIDENCE_SAME_REGISTRATION]: true,
    [FORM_FIELDS.REGISTRATION_DADATA_FULL]: addressDadata,

    [FORM_FIELDS.EMPLOYMENT_TYPE]: 'Employment',
    [FORM_FIELDS.ORGANIZATION_NAME]: 'КАНАТ',
    [FORM_FIELDS.EMPLOYER_INN]: '5249000424',
    [FORM_FIELDS.EMPLOYER_TYPE]: 'PAO',
    [FORM_FIELDS.AREA_OF_EMPLOYMENT]: 'StateMunicipalAdministration',
    [FORM_FIELDS.LAST_EXPERIENCE]: '01.03.2016',
    [FORM_FIELDS.JOB_TITLE]: 'менеджер',
    [FORM_FIELDS.JOB_TYPE]: 'headOfDepartment',
    [FORM_FIELDS.WORK_PHONE]: '+7 (499) 555-55-55',
    [FORM_FIELDS.WORK_ADDRESS]: 'Нижегородская обл, г Дзержинск, ул Попова, д 78',
    [FORM_FIELDS.MONTHLY_SALARY]: '70000',
    [FORM_FIELDS.LAST_EXPERIENCE_START_MONTH]: '03',
    [FORM_FIELDS.LAST_EXPERIENCE_START_YEAR]: '2016',
    [FORM_FIELDS.INCOME_CONFIRMATION]: 'Ndfl2',
    [FORM_FIELDS.SENIORITY]: 'P3Y',

    [FORM_FIELDS.ADDITIONAL_PHONE]: '+7 (999) 555-55-59',
    [FORM_FIELDS.ADDITIONAL_PHONE_OWNER]: 'MyNumber',
    [FORM_FIELDS.EDUCATION]: 'IncompleteHigher',
    [FORM_FIELDS.RESIDENCE_STREET]: 'г Москва, Мещерский пр-кт, д 1/15',
    [FORM_FIELDS.RESIDENCE_ROUTE]: '6.83.',
    [FORM_FIELDS.RESIDENCE_APARTMENT]: '42',
    [FORM_FIELDS.RESIDENCE_DADATA_FULL]: addressDadata,
    [FORM_FIELDS.FAMILY_STATUS]: 'Married',
    [FORM_FIELDS.SNILS]: '11697338589',

    [FORM_FIELDS.CAR_BRAND]: 'Opel',
    [FORM_FIELDS.CAR_MODEL]: 'Astra',
    [FORM_FIELDS.CAR]: 'Foreign',
    [FORM_FIELDS.CAR_YEAR]: '2015',
    [FORM_FIELDS.CAR_PRICE]: '490000',
    [FORM_FIELDS.RENT_APARTMENT]: '20000',
    [FORM_FIELDS.NUMBER_OF_DEPENDENTS]: 'None',
    [FORM_FIELDS.ADDITIONAL_INCOME]: '15000',
    [FORM_FIELDS.PROPERTY_TYPE]: 'Flat',
};

/** Добавляет функцию автозаполения анкеты в Window */
export const useAutoFill = (enabled = false) => {
    const dispatch = useDispatch();

    useEffect(() => {
        /** Only for check integration */
        if (typeof window === 'object') {
            (<any>window).checkSentryCapture = () => {
                captureException(new Error('Check integration error'), { extra: { foo: 'bar' } });
                console.log('%c👌', 'font-size: 30px');
            };
        }

        if (!enabled) {
            return;
        }

        if (typeof window === 'object') {
            (<any>window).fillCreditForm = () => {
                dispatch(extendFormData(data));
                console.log('%c👌', 'font-size: 30px');
            };
        }
    }, [dispatch, enabled]);
};

export { data as autoFillData };
