import { useEffect } from 'react';
import { useLocation } from 'react-use';
import { FORM_FIELDS, MAP_FORM_FIELDS_TO_QUERY } from '../../../constants';
import { useFormData } from '../../../store/selectors/formStore';

const keys = [FORM_FIELDS.AMOUNT, FORM_FIELDS.PERIOD, FORM_FIELDS.PURPOSE] as const;

/** Создает get-параметры на основе некоторых полей анкеты */
export const useQueryData = (enabled = true, customFieldsMap: Record<string, unknown> = {}) => {
    const location = useLocation();
    const { search = '', pathname = '' } = location;
    const formData = useFormData();

    useEffect(() => {
        if (!enabled) {
            return;
        }

        const currentSearch = search.slice(1);
        const searchParams = new URLSearchParams(currentSearch);

        for (const field of [...keys, ...Object.keys(customFieldsMap)]) {
            const queryKey = MAP_FORM_FIELDS_TO_QUERY[field] || customFieldsMap[field];
            if (formData[field] && queryKey) {
                searchParams.set(queryKey, String(formData[field]));
            }
        }

        const newSearch = searchParams.toString();

        if (newSearch !== currentSearch) {
            window.history.replaceState(null, document.title, [pathname, newSearch].filter(Boolean).join('?'));
        }
    }, [enabled, formData, search, pathname]);
};
