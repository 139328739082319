import { getStore } from '../../store/client';
import { CREDIT_SELECTION_ABC_EXPERIMENT_IDS, PAGE } from '../../constants';
import { useSelection } from '../../store/reducers/selection';
import { useExperiments } from '../../store/reducers/abData';

type Variants = '0' | '1';

/** CF-1309 */
export const checkAbAdditionalQuestions = (variant: Variants): boolean => {
    const {
        abData: { experiments },
        selection: { page },
    } = getStore().getState();

    return [PAGE.LANDING].includes(page)
        ? (experiments[CREDIT_SELECTION_ABC_EXPERIMENT_IDS.ADDITIONAL_QUESTIONS] as Variants) === variant
        : false;
};

/** CF-1309 */
export const useAbAdditionalQuestions = (): Variants => {
    const { page } = useSelection();
    const experiments = useExperiments();

    return [PAGE.LANDING].includes(page)
        ? (experiments[CREDIT_SELECTION_ABC_EXPERIMENT_IDS.ADDITIONAL_QUESTIONS] as Variants)
        : '0';
};
