import { useEffect, useRef } from 'react';
import { useUser } from '../../../store/reducers/user';
import { FORM_FIELDS } from '../../../constants';
import {
    clearUnauthorizedForm,
    loadUnauthorizedForm,
    saveUnauthorizedForm,
    saveLocalForm,
    loadLocalForm,
} from '../../../helpers/form.new';
import { useDispatch } from 'react-redux';
import { extendFormData } from '../../../store/reducers/formStore';
import { useFormData } from '../../../store/selectors/formStore';
import { removeUndefined } from '../../../helpers';
import { useAbAdditionalQuestions } from '../../../helpers/experiments/additional.questions';

/** Сохраняет данные первого шага анкеты в локальное хранилище, пока пользователь не авторизован */
export const useLocal = (enabled = false) => {
    const user = useUser();
    const dispatch = useDispatch();
    const formData = useFormData();
    const formDataRef = useRef(formData);

    const additionalQuestionsVariant = useAbAdditionalQuestions();
    const isShowAdditionalQuestions = additionalQuestionsVariant === '1';

    useEffect(() => {
        if (!enabled) {
            return;
        }
        formDataRef.current = formData;
    }, [formData, enabled]);

    useEffect(() => {
        if (!enabled) {
            return;
        }

        const dataParsed = Object.assign({}, loadUnauthorizedForm(), isShowAdditionalQuestions ? loadLocalForm() : {});

        if (Object.keys(dataParsed).length) {
            dispatch(extendFormData({ ...dataParsed, ...removeUndefined(formDataRef.current, ['']) }));
        } else {
            clearUnauthorizedForm();
        }
    }, [user, enabled, dispatch, isShowAdditionalQuestions]);

    useEffect(() => {
        if (!enabled) {
            return;
        }

        saveLocalForm(formData);

        const passport = formData[FORM_FIELDS.PASSPORT_NUMBER];

        if (!passport) {
            saveUnauthorizedForm(formData);
        }
    }, [formData, user, enabled, dispatch]);
};
