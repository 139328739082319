import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SuggestAsync } from '.';
import { provider } from '../../../../helpers/client';
import { MainItem, SubitemItem } from './menu/index.styles';
import { setCurrentLocation } from '../../../../store/reducers/locations';
import { ISuggestProps } from './types';
import { setClientCookie } from '../../../../helpers/cookies';
import { CS_LOCATION_COOKIE_KEY } from '../../../../constants';

type IAddressSuggestProps<T> = Pick<ISuggestProps<T>, 'input' | 'onBlur' | 'onChange' | 'onSelect' | 'customIcon'>;

export const FormLocationSuggest = ({
    onSelect,
    onChange,
    input,
    ...props
}: IAddressSuggestProps<Location.SuggestItem>) => {
    const [rawValue, setRawValue] = useState(input.value);

    useEffect(() => {
        setRawValue(input.value);
    }, [input.value]);

    const dispatch = useDispatch();

    const setGlobalRegion = (region: Location.SuggestItem) => {
        setClientCookie(CS_LOCATION_COOKIE_KEY, region.route);
        dispatch(setCurrentLocation(region));
    };

    const onSelectInner = useCallback(
        (item: Location.SuggestItem) => {
            setGlobalRegion(item);
            if (onSelect) {
                onSelect(item);
            }
        },
        [onSelect],
    );

    const onChangeInner = useCallback(
        (event: FE.FieldChangeEvent) => {
            setRawValue(event.target.value);
            if (onChange) {
                onChange(event);
            }
        },
        [onChange],
    );

    const getOptions = useCallback(async (value) => {
        const raw = await provider.locationSuggest({ query: value, includeVillages: true, limit: 8 });
        const distinct = new Map(raw.map((item) => [item.fullName, item]));
        return Array.from(distinct.values());
    }, []);

    return (
        <SuggestAsync<Location.SuggestItem>
            {...props}
            onSelect={onSelectInner}
            onChange={onChangeInner}
            getOptions={getOptions}
            onlyList
            onlyRus
            input={{ ...input, value: rawValue }}
            mapperOption={(option) => ({ name: option.name, value: option.name, option })}
            optionRender={(mapped) => (
                <div>
                    <MainItem>{mapped.option?.name}</MainItem>
                    <SubitemItem>{mapped.option?.regionName}</SubitemItem>
                </div>
            )}
            emptyText={'Ничего не найдено, укажите конкретный город'}
        />
    );
};
