import React, { FC, useCallback } from 'react';
import { FORM_FIELDS, LABELS } from '../../../constants';
import { FormLocationSuggest } from '../../form/ui/suggest/location';
import { extendFormData } from '../../../store/reducers/formStore';
import { useDispatch } from 'react-redux';
import { ControllerRenderProps, FieldValues, UseFormReturn } from 'react-hook-form';

type Props = {
    field: ControllerRenderProps<FieldValues, string>;
    form: UseFormReturn<FieldValues, any>;
    customLabel?: string;
};

export const RegionField: FC<Props> = (props) => {
    const {
        field: { onBlur, onChange: onChangeField, ...field },
        form,
        customLabel,
    } = props;
    const dispatch = useDispatch();

    const {
        watch,
        formState: { errors, dirtyFields: _ },
        clearErrors,
        setValue,
    } = form;

    const regionLocationRoute = watch(FORM_FIELDS.REGION_LOCATION_ROUTE);

    const changeLocation = useCallback(
        (region: Location.SuggestItem) => {
            setValue(FORM_FIELDS.REGION_LOCATION_ROUTE, region.route, { shouldValidate: false });
            dispatch(
                extendFormData({
                    [FORM_FIELDS.REGION_FULL_NAME]: region.fullName,
                    [FORM_FIELDS.REGION_ID]: region.id,
                    [FORM_FIELDS.REGION]: region.name,
                    [FORM_FIELDS.KLADR_ID]: region.kladr,
                    [FORM_FIELDS.REGION_LOCATION_ROUTE]: region.route,
                }),
            );
        },
        [dispatch, setValue],
    );
    const onRegionChange = useCallback(
        (event: FE.FieldChangeEvent) => {
            if (regionLocationRoute) {
                dispatch(
                    extendFormData({
                        [FORM_FIELDS.REGION_FULL_NAME]: '',
                        [FORM_FIELDS.REGION_ID]: '',
                        [FORM_FIELDS.REGION]: '',
                        [FORM_FIELDS.KLADR_ID]: '',
                        [FORM_FIELDS.REGION_LOCATION_ROUTE]: '',
                    }),
                );
                clearErrors([FORM_FIELDS.REGION_LOCATION_ROUTE]);
                setValue(FORM_FIELDS.REGION_LOCATION_ROUTE, '', { shouldValidate: false });
            }

            onChangeField(event);
        },
        [clearErrors, setValue, regionLocationRoute, dispatch, onChangeField],
    );

    return (
        <FormLocationSuggest
            input={{
                ...field,
                label: customLabel || LABELS[FORM_FIELDS.REGION],
                errorMessage: errors[FORM_FIELDS.REGION]?.message || errors[FORM_FIELDS.REGION_LOCATION_ROUTE]?.message,
            }}
            onSelect={changeLocation}
            onChange={onRegionChange}
            onBlur={onBlur}
        />
    );
};
